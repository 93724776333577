export class EnterpriseModel {
    EnterprisePartnerId:Number;
    IMEI:string; 
    BrandName:string;
    ModelName:string;
    Capacity:string;
    DeviceModelID:string;
    ValidityDays:Number;
    DeviceCategoryID:Number
    TradeInDeviceStartDate: Date;
    TopTradeInDevice: Number;
    SerialNumber: string
    CacheManagerID:Number
    }
      